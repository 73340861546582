body {
  background-color: #faebd7;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.yellow {
  color: #253d25;
}

.indent {
  text-indent: 30px;
}

* {
  font-family: baskerville;
}

p {
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*---------------------Footer----------------------*/

.foot-bar {
  background-color: #f2c993;
  min-height: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.attribution {
  display: inline-block;
  background-color: #f2c993;
  font-size: 0.5rem;
  height: 100%;
  width: 100%;
}

#contact {
  height: 65px;
  width: 85px;
  font-size: 20px;
}

#fb-img {
  height: 65px;
}

#contact:hover {
  cursor: pointer;
}

#facebook-button:hover {
  cursor: pointer;
}

/*---------------Header----------------*/

#head {
  background-color: #f2c993;
  color: #3d644a;
  text-align: center;
  padding-top: 8vh;
  margin-top: 0;
}

.head-bar {
  background-color: #e94b02;
  height: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#head h1 {
  margin: 0;
  font-size: 4rem;
  margin-bottom: 10vh;
}

.nav-button {
  color: white;
  text-shadow: 1px 2px black;
  font-size: 20px;
  height: 60px;
  width: 100px;
  border-radius: 15px;
  background-image: linear-gradient(325deg, #3d644a, #3d644a, #b5ceb5, #253d25);
  border-right: 2px solid #253d25;
  border-bottom: 2px solid #253d25;
  border-left: 2px solid #8fbe8f;
  border-top: 2px solid #8fbe8f;
}

.nav-button:hover {
  opacity: 0.5;
  cursor: pointer;
}

/*-------------Home Page-----------------*/

#home-body {
  background-image: url("./media/image2_1.jpeg");
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#mission-statement {
  width: 60vw;
}

#mission-statement > p {
  margin: 0;
  font-size: 1.6rem;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 5px;
}

#event-quote {
  height: 3rem;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  max-width: 55vw;
  border: 1px #e94b02 solid;
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.event-display {
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.event-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  font-size: 1.4rem;
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px #e94b02 solid;
  max-width: 25vw;
  margin: 10px 0;
}
/* --------------Announcement Box---------------- */

#event-container {
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 15px 3vw;
  border: 1px #e94b02 solid;
}

#event-container.closed button {
  position: absolute;
  bottom: 10px;
  left: 30vw;
  right: 30vw
}

#event-container h3, h2 {
  text-align: center;
}

#event-container.closed {
  position: relative;
  max-height: 250px;
  overflow: hidden;
}

#event-container.open {
  position: absolute;
  top: 10vh;
  left: 15vw;
  right: 15vw;
  width: auto;
  z-index: 7;
  background-color: rgb(255, 255, 255);
}

/*----------------Gallery Entry-------------------*/

#gallery-nav {
  height: 700px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.photo-nav-button {
  position: relative;
  width: 250px;
}

.photo-nav-button img {
  width: 250px;
  height: 250px;
}

.photo-nav-button p {
  text-align: center;
  color: rgb(66, 66, 66);
}

.photo-nav-button:hover {
  opacity: 0.5;
  cursor: pointer;
}

/*--------------photo gallery shenanigans----------*/

#photo-gallery {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.thumb {
  border: 1px solid grey;
  height: 220px;
  width: 220px;
}

.thumb:hover {
  opacity: 0.5;
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 30px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 100vw;
}

.description {
  color: white;
  text-align: center;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 3;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/*-----------------About Page-----------------*/

#history-image {
  float: left;
  width: 600px;
  margin: 10px;
}

#csrc-img {
  float: right;
  width: 600px;
  margin: 10px;
}

.corner-school-desc {
  margin: 20px;
}

.corner-school-desc h2 {
  text-align: center;
}

/* ----------------Admin Page--------------------- */

#admin-body {
  display: flex;
  justify-content: space-around;
}

#gallery-form {
  width: 40vw
}

.edit-thumb {
  display: inline-block;
  height: 150px;
  width: 150px
}

/*----------For mobile responsiveness(ish)--------*/

@media screen and (max-width: 736px) {
  .foot-bar {
    background-color: #f2c993;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    justify-self: center;
  }

  .head-bar {
    background-color: #f2c993;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    justify-self: center;
  }

  #home-body {
    display: inline-block;
  }

  #cover {
    display: inline-block;
    align-items: center;
    justify-items: center;
    width: 300px;
  }

  #cover img {
    width: 320px;
  }

  #mission-statement {
    position: relative;
    display: inline-block;
    align-self: center;
    justify-self: center;
    background-color: white;
    opacity: 0.7;
    width: 320px;
    margin: 10px;
    padding: 0;
  }

  #event-quote {
    display: inline-block;
    width: 320px;
    margin: 10px;
  }

  #gallery-nav {
    height: 800px;
  }

  #photo-gallery {
    display: inline-block;
    margin-top: 20px;
  }

  .thumb {
    width: 100%;
    height: auto;
  }

  #history-image {
    display: inline-block;
    float: none;
    width: 100%;
  }

  #csrc-img {
    display: inline-block;
    float: none;
    width: 100%;
  }
}
